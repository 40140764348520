<template>
  <section class="change--pass-wrapper">
    <section-loading :show="loading" />
    <div class="title--text">Bank Account</div>
    <div role="alert" class="alert alert-warning mt-2">
      {{ $t('bankForm.warning') }}
    </div>
    <form @submit.prevent="postData">
      <div class="input--wrapper">
        <div class="form--group" :class="{ 'has-error': validation.hasError('selectedBank') }">
          <div class="left--col">
            <label for="bankFormBankList">
              <span>{{ $t('bankForm.bankName') }}</span>
            </label>
          </div>
          <div class="right--col">
            <multiselect
              name="searchKeyword"
              id="bankFormBankList"
              v-model="selectedBank"
              :optionsLimit="7"
              :options="bankList"
              :searchable="true"
              :close-on-select="true"
              track-by="id"
              label="name"
            >
              <template slot="caret">
                <div class="multiselect__select select--search">
                  <i class="ion-ios-search"></i>
                </div>
              </template>
            </multiselect>
            <span class="val-error">{{ validation.firstError('selectedBank') }}</span>
          </div>
        </div>
        <div class="form--group" :class="{ 'has-error': validation.hasError('accountName') }">
          <div class="left--col">
            <label for="account-name">
              <span>{{ $t('bankForm.accountName') }}</span>
            </label>
          </div>
          <div class="right--col">
            <input
              :class="{ 'has-error': validation.hasError('accountName') }"
              id="account-name"
              v-model="accountName"
              autocomplete="accountName"
              class="basic--input"
            />
            <span class="val-error">{{ validation.firstError('accountName') }}</span>
          </div>
        </div>
        <div class="form--group" :class="{ 'has-error': validation.hasError('accountNumber') }">
          <div class="left--col">
            <label for="bankFormAccountNumber">
              <span>{{ $t('bankForm.accountNumber') }}</span>
            </label>
          </div>
          <div class="right--col">
            <cleave
              id="bankFormAccountNumber"
              v-model="accountNumber"
              :class="validation.hasError('accountNumber') ? 'is-invalid' : ''"
              :options="cleaveOption"
              autocomplete="accountNumber"
              class="basic--input"
              type="text"
              name="accountNumber"
            />
            <span class="val-error">{{ validation.firstError('accountNumber') }}</span>
          </div>
        </div>
        <div
          v-show="showPhoneNumberField"
          class="form--group"
          :class="{ 'has-error': validation.hasError('phoneNumber') }"
        >
          <div class="left--col">
            <label for="bankFormPhoneNumber">
              <span>{{ $t('bankForm.phoneNumber') }}</span>
            </label>
          </div>
          <div class="right--col">
            <cleave
              id="bankFormPhoneNumber"
              v-model="phoneNumber"
              :class="validation.hasError('phoneNumber') ? 'is-invalid' : ''"
              :options="phoneCleaveOption"
              autocomplete="phoneNumber"
              class="basic--input"
              type="text"
              name="phoneNumber"
            />
            <span class="val-error">{{ validation.firstError('phoneNumber') }}</span>
          </div>
        </div>
        <h5 class="mt-4">{{ $t('bankForm.validateHeader') }}</h5>
        <hr />
        <div class="form--group" :class="{ 'has-error': validation.hasError('email') }">
          <div class="left--col">
            <label for="email">
              <span>{{ $t('loginRegister.emailR') }}</span>
            </label>
          </div>
          <div class="right--col">
            <input
              :class="{ 'has-error': validation.hasError('email') }"
              id="email"
              v-model="email"
              placeholder="Email"
              class="basic--input"
            />
            <span class="val-error">{{ validation.firstError('email') }}</span>
          </div>
        </div>
        <div class="form--group" :class="{ 'has-error': validation.hasError('password') }">
          <div class="left--col">
            <label for="confirm-pass">
              <span>{{ $t('loginRegister.passR') }}</span>
            </label>
          </div>
          <div class="right--col">
            <div class="input--group">
              <input
                :class="{ 'has-error': validation.hasError('password') }"
                id="confirm-pass"
                v-model="password"
                placeholder="Password"
                class="basic--input password--input"
                :type="showPass ? 'text' : 'password'"
              />
              <div class="append--right" @click="() => (showPass = !showPass)">
                <v-icon v-if="showPass">mdi-eye</v-icon>
                <v-icon v-else>mdi-eye-off</v-icon>
              </div>
            </div>
            <span class="val-error">{{ validation.firstError('password') }}</span>
          </div>
        </div>
      </div>
      <div class="main--btn">
        <button class="btn btn-primary" type="submit">
          {{ $t('profile.saveBtn') }}
        </button>
      </div>
    </form>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { Validator } from 'simple-vue-validator';
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
const SectionLoading = () => import('@/components/content-loading/section-loading');

import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.id.js';
export default {
  components: { Multiselect, SectionLoading, Cleave },
  props: {
    showNotNowBtn: {
      type: Boolean,
      default: false,
    },
    showPhoneNumberField: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    cleaveOption: {
      prefix: '',
      numeral: true,
      numeralPositiveOnly: true,
      noImmediatePrefix: true,
      rawValueTrimPrefix: true,
      numeralDecimalMark: '',
      delimiter: '',
      numeralIntegerScale: 50,
      numeralDecimalScale: 0,
      stripLeadingZeroes: false,
    },
    phoneCleaveOption: {
      phone: true,
      phoneRegionCode: 'id',
    },
    showPass: false,
  }),
  computed: {
    ...mapState({
      bankList: (state) => state.v2.bankForm.bankList,
      loading: (state) => state.v2.bankForm.loading,
    }),
    selectedBank: {
      get() {
        return this.$store.state.v2.bankForm.selectedBank;
      },
      set(val) {
        this.$store.commit('v2/bankForm/SET_SELECTED_BANK', val);
      },
    },
    accountName: {
      get() {
        return this.$store.state.v2.bankForm.accountName;
      },
      set(val) {
        this.$store.commit('v2/bankForm/SET_ACCOUNT_NAME', val);
      },
    },
    accountNumber: {
      get() {
        return this.$store.state.v2.bankForm.accountNumber;
      },
      set(val) {
        this.$store.commit('v2/bankForm/SET_ACCOUNT_NUMBER', val);
      },
    },
    phoneNumber: {
      get() {
        return this.$store.state.v2.bankForm.phoneNumber;
      },
      set(val) {
        this.$store.commit('v2/bankForm/SET_PHONE_NUMBER', val);
      },
    },
    email: {
      get() {
        return this.$store.state.v2.bankForm.email;
      },
      set(val) {
        this.$store.commit('v2/bankForm/SET_EMAIL', val);
      },
    },
    password: {
      get() {
        return this.$store.state.v2.bankForm.password;
      },
      set(val) {
        this.$store.commit('v2/bankForm/SET_PASSWORD', val);
      },
    },
  },
  validators: {
    selectedBank(value) {
      return Validator.value(value).required(this.$i18n.t('errors.bankName.required'));
    },
    accountName(value) {
      return Validator.value(value).required(this.$i18n.t('errors.accountName.required'));
    },
    accountNumber(value) {
      return Validator.value(value).required(this.$i18n.t('errors.accountNumber.required'));
    },
    phoneNumber(value) {
      return Validator.value(value).required(this.$i18n.t('errors.phone.required'));
    },
    email(value) {
      return Validator.value(value).required().email();
    },
    password(value) {
      return Validator.value(value).required();
    },
  },
  methods: {
    async postData() {
      const isValid = await this.$validate();
      if (isValid) {
        try {
          await this.$store.dispatch('v2/bankForm/postData');
          this.$swal(
            this.$i18n.t('bankForm.swal.title'),
            this.$i18n.t('bankForm.swal.message'),
            'success',
          );
          this.$emit('postDone');
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }
    },
  },
  mounted() {
    this.$store.dispatch('v2/bankForm/getFormData');
  },
};
</script>
